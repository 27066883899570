import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, SessionAuthState, AuthUser, SessionContextType } from '../@types/auth';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type SessionAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    oidcRefreshTokenExpired: boolean;
    googleRefreshTokenExpired: boolean;
    user: AuthUser;
    loginUrl: string | null;
    logoutUrl: string | null;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  // [Types.Logout]: undefined;
  [Types.Logout]: {
    isAuthenticated: boolean;
    user: null;
    loginUrl: string | null;
    logoutUrl: string | null;
  };
  [Types.Register]: {
    user: AuthUser;
  };
};

export type SessionActions = ActionMap<SessionAuthPayload>[keyof ActionMap<SessionAuthPayload>];

const initialState: SessionAuthState = {
  isAuthenticated: false,
  oidcRefreshTokenExpired: false,
  googleRefreshTokenExpired: false,
  isInitialized: false,
  user: null,
  loginUrl: null,
  logoutUrl: null,
};

const SessionReducer = (state: SessionAuthState, action: SessionActions) => {
  console.log('state: ', state);
  console.log('action: ', action);
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        loginUrl: action.payload?.loginUrl,
        logoutUrl: action.payload?.logoutUrl,
        oidcRefreshTokenExpired: action.payload.oidcRefreshTokenExpired,
        googleRefreshTokenExpired: action.payload.googleRefreshTokenExpired,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<SessionContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(SessionReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get('accounts/session/');
        const {
          user,
          oidc_refresh_token_expired: oidcRefreshTokenExpired,
          google_refresh_token_expired: googleRefreshTokenExpired,
        } = response.data;
        console.log(response);
        console.log(`oidcRefreshTokenExpired: ${oidcRefreshTokenExpired}`);
        console.log(`googleRefreshTokenExpired: ${googleRefreshTokenExpired}`);
        if (oidcRefreshTokenExpired) {
          try {
            const response = await axios.get('accounts/login/');
            const loginUrl = response?.data?.login_url;
            const logoutUrl = response?.data?.logout_url;
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                loginUrl: loginUrl,
                logoutUrl: logoutUrl,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          } catch (err) {
            console.log(err);
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                loginUrl: null,
                logoutUrl: null,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          }
        } else if (googleRefreshTokenExpired) {
          try {
            const response = await axios.get('accounts/login/');
            const loginUrl = response?.data?.login_url;
            const logoutUrl = response?.data?.logout_url;
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                loginUrl: loginUrl,
                logoutUrl: logoutUrl,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          } catch (err) {
            console.log(err);
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                loginUrl: null,
                logoutUrl: null,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          }
        } else {
          try {
            const response = await axios.get('accounts/login/');
            const loginUrl = response?.data?.login_url;
            const logoutUrl = response?.data?.logout_url;
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user,
                loginUrl: loginUrl,
                logoutUrl: logoutUrl,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          } catch (error) {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user,
                loginUrl: null,
                logoutUrl: null,
                oidcRefreshTokenExpired: false,
                googleRefreshTokenExpired: false,
              },
            });
          }
        }
      } catch (err) {
        console.log(err);

        try {
          const response = await axios.get('accounts/login/');
          const loginUrl = response?.data?.login_url;
          const logoutUrl = response?.data?.logout_url;
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              loginUrl: loginUrl,
              logoutUrl: logoutUrl,
              oidcRefreshTokenExpired: false,
              googleRefreshTokenExpired: false,
            },
          });
        } catch (err) {
          console.log(err);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              loginUrl: null,
              logoutUrl: null,
              oidcRefreshTokenExpired: false,
              googleRefreshTokenExpired: false,
            },
          });
        }
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/account/login', {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async (logout_url: string, login_url: string) => {
    console.log('SessionContext logout');
    console.log('logoutString: ', logout_url);

    try {
      const response = await axios.post('accounts/logout/');
      console.log('logout response: ', response);
      const { logout_redirect_url } = response?.data;
      if (logout_redirect_url) {
        window.location.href = logout_redirect_url;
      }
    } catch (error) {
      console.log('failed to log out: ', error);
      dispatch({
        type: Types.Logout,
        payload: {
          isAuthenticated: false,
          user: null,
          logoutUrl: logout_url,
          loginUrl: login_url,
        },
      });
    }

    // dispatch({
    //   type: Types.Logout,
    //   payload: {
    //     user,
    //   },
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'session',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
