import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUriWithParam } from '../../../utils/urls';
import usePostMessage from '../../../hooks/usePostMessage';
import { POPUP_OAUTH2_COMPLETE } from './constants';
// ----------------------------------------------------------------------

export default function LoginSection() {
  const navigate = useNavigate();
  const { loginUrl } = useAuth();
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [fullLoginUrl, setFullLoginUrl] = useState<string>('');

  const refreshPage = () => {
    navigate(0);
  };

  useEffect(() => {
    if (loginUrl) {
      const redirectUrl = `${process.env.REACT_APP_BASE_URL}/auth/oauth2callback`;
      console.log('COMXInteractive Current url: ', redirectUrl);
      setFullLoginUrl(getUriWithParam(loginUrl, { next: redirectUrl }));
    }
  }, [loginUrl]);

  useEffect(() => {
    if (fullLoginUrl) {
      console.log('COMXInteractive fullLoginUrl: ', fullLoginUrl);
    }
  }, [fullLoginUrl]);

  usePostMessage(async (send, payload: any) => {
    console.log('COMXInteractive receiving message in comx LoginComponent with payload: ', payload);
    if (
      payload &&
      payload?.postMessageType &&
      payload?.postMessageType === 'SELECT_CONTACT' &&
      payload?.integrationId
    ) {
      if (localStorage.getItem('postMessagePayload') === null) {
        console.log('COMXInteractive trying to save payload to LocalStorage: ', payload);
        try {
          localStorage.setItem('postMessagePayload', JSON.stringify(payload));
        } catch (error) {
          console.log('COMXInteractive payload saved to LocalStorage: ', payload);
        }
      }
    }
  });

  // const redirectToOIDCLoginPage = () => {
  //   console.log('COMXInteractive redirectToOIDCLoginPage');
  //   if (loginUrl) {
  //     let fullLoginUrl = getUriWithParam(loginUrl, { next: redirectUrl });
  //     console.log('COMXInteractive fullLoginUrl', fullLoginUrl);
  //     window.location.href = fullLoginUrl;
  //   }
  // };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      console.log('popup timer');
      if (!externalPopup) {
        console.log('popup is null');
        timer && clearInterval(timer);
        return;
      }
      if (localStorage.getItem(POPUP_OAUTH2_COMPLETE)) {
        const popupOAuth2Complete = localStorage.getItem(POPUP_OAUTH2_COMPLETE);
        localStorage.removeItem(POPUP_OAUTH2_COMPLETE);
        setExternalPopup(null);
        if (timer) {
          clearInterval(timer);
        }
        refreshPage();
        console.log(
          'COMXInteractive popupOAuth2Complete exists in local storage: ',
          popupOAuth2Complete
        );
      }
    }, 500);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [externalPopup]);

  const redirectToOIDCLoginPageInPopup = () => {
    console.log('COMXInteractive redirectToOIDCLoginPageInPopup');

    if (loginUrl) {
      const popupWidth = 500;
      const popupHeight = 400;
      const left = window.screenX + (window.outerWidth - popupWidth) / 2;
      const top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
      const strWindowFeatures = `toolbar=no,menubar=no,width=${popupWidth},height=${popupHeight},left=${left},top=${top}`;
      const popup = window.open(fullLoginUrl, 'Comx Interactive Login', strWindowFeatures);
      setExternalPopup(popup);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Button variant="contained" fullWidth={true} onClick={redirectToOIDCLoginPageInPopup}>
          Sign In
        </Button>
      </Stack>
    </>
  );
}
